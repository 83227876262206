window.addEventListener('load', () => {
// Add your JS customizations here
    let menuBurger = document.querySelector('.tog-nav'),
        menuCallBurger = document.querySelector('.header__call'),
        menuCall = document.querySelector('.header-contacts-wrap--call'),
        menuCallClose = document.querySelector('.header-contacts__close'),
        menuCallChevron = document.querySelector('.header__call span'),
        html = document.querySelector('html');

    menuBurger.addEventListener('click', function () {
        html.classList.toggle('menu-opened');
    });

    menuCallBurger.addEventListener('click', function () {
        menuCall.classList.toggle('opened');
        menuCallChevron.classList.toggle('opened');
    });

    menuCallClose.addEventListener('click', function () {
        menuCall.classList.remove('opened');
        menuCallChevron.classList.remove('opened');
    });

    // When the user scrolls the page, execute myFunction
    window.onscroll = function () {
        myFunction()
    };

    // Get the header
    let header = document.getElementById("header");
    // Get the offset position of the navbar
    let sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
        if (window.pageYOffset > sticky) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
    }

    //FANCYBOX
    let fancyboxEl = $("[data-fancybox]");
    if (fancyboxEl.length > 0) {
        fancyboxEl.fancybox({
            touch: false,
            autoFocus: false
        });
        $('.popup__close').on('click', function () {
            $.fancybox.close();
        });
    }

    // VIDEO

    $('.wp-video').each(function () {

        let ths = $(this),
            autoPlayValue = '?rel=0;&autoplay=1&mute=0&loop=1',
            currentPlayIcon = ths.find('.play'),
            currentVideo = ths.find('iframe'),
            currentVideoSrc = currentVideo.attr('src'),
            newSrc ='';

        newSrc = currentVideoSrc.split('?')[0];
        currentVideo.attr('src', newSrc);

        currentPlayIcon.on('click', function () {
            currentVideo.attr('src', currentVideoSrc + autoPlayValue);
            currentPlayIcon.addClass('hide');
        });

    });

    // sliders

    if (document.querySelector('.gallery-advant-slider')) {
        new Swiper('.gallery-advant-slider', {
            loop: true,
            slidesPerView: 1,
            slidesPerScroll: 1,
            centeredSlides: true,
            spaceBetween: 20,
            speed: 500,
            // autoplay: {
            //     delay: 5000,
            // },

            // If we need pagination
            pagination: {
                type: 'fraction',
                el: '.gallery-advant-slider__pagination',
                clickable: true
            },

            // Navigation arrows
            navigation: {
                nextEl: '.gallery-advant-arrow-next',
                prevEl: '.gallery-advant-arrow-prev',
            },
        });
    }

    if (document.querySelector('.advantages-slider')) {

        new Swiper('.advantages-slider', {
            loop: true,
            slidesPerView: 1,
            slidesPerScroll: 1,
            speed: 500,
            autoplay: {
                delay: 20000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },

            on: {
                slideChange: function () {
                    const stepsField = document.querySelector('.advantages-slider__steps span');
                    const totalSteps = this.slides.length - 2;
                    let currentStep = this.realIndex + 1;
                    stepsField.textContent = String(currentStep) + '/' + String(totalSteps);

                    $('.advantages-slider__steps').circleProgress({
                        value: currentStep / totalSteps,
                        animationStartValue: currentStep / totalSteps,
                        fill: '#F03E3E',
                        size: 72,
                    });
                }
            },

            // If we need pagination
            pagination: {
                el: '.advantages-slider__pagination',
                type: 'bullets',
                clickable: true
            },


            // Navigation arrows
            navigation: {
                nextEl: '.advantages-arrow-next',
                prevEl: '.advantages-arrow-prev',
            },

        });


    }
    if (document.querySelector('.plan-slider')) {
        new Swiper('.plan-slider', {
            // loop: true,
            slidesPerView: 1,
            slidesPerScroll: 1,
            spaceBetween: 0,
            speed: 500,
            autoplay: {
                delay: 5000,
            },

            // If we need pagination
            pagination: {
                el: '.plan-slider__pagination',
                clickable: true
            },

            // Navigation arrows
            navigation: {
                nextEl: '.plan-arrow-next',
                prevEl: '.plan-arrow-prev',
            },
            breakpoints: {
                667: {
                    slidesPerView: 2.6,
                    spaceBetween: 30,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            }
        });
    }
    if (document.querySelector('.stages-slider')) {
        new Swiper('.stages-slider', {
            loop: true,
            slidesPerView: 1,
            slidesPerScroll: 1,
            speed: 500,
            autoplay: {
                delay: 5000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },

            // If we need pagination
            pagination: {
                el: '.stages-slider__pagination',
                clickable: true
            },

            // Navigation arrows
            navigation: {
                nextEl: '.stages-arrow-next',
                prevEl: '.stages-arrow-prev',
            },
        });
    }
    if (document.querySelector('.gallery-slider')) {
        new Swiper('.gallery-slider', {
            loop: true,
            slidesPerView: 1.23,
            slidesPerScroll: 1,
            centeredSlides: true,
            spaceBetween: 20,
            speed: 500,
            autoplay: {
                delay: 5000,
            },

            // If we need pagination
            pagination: {
                el: '.gallery-slider__pagination',
                clickable: true
            },

            // Navigation arrows
            navigation: {
                nextEl: '.gallery-arrow-next',
                prevEl: '.gallery-arrow-prev',
            },
            breakpoints: {
                1024: {
                    slidesPerView: 1.19,
                },
                1200: {
                    slidesPerView: 1.24,
                },
                1440: {
                    slidesPerView: 1.16,
                }
            }
        });
    }

    if (document.querySelector('.single-steps-slider')) {

        new Swiper('.single-steps-slider', {
            loop: true,
            slidesPerView: 1,
            slidesPerScroll: 1,
            speed: 500,
            autoplay: {
                delay: 5000,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },

            // If we need pagination
            pagination: {
                el: '.single-steps-slider__pagination',
                type: 'bullets',
                clickable: true
            },


            // Navigation arrows
            navigation: {
                nextEl: '.single-steps-arrow-next',
                prevEl: '.single-steps-arrow-prev',
            },

        });


    }
})

